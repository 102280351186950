import Service from "../Service";

const resource = "FrzLinesPackingViews/";

export default {
    listLineas(item,requestID) {
        return Service.post(resource + "listLineas", item, {
            params: { requestID: requestID },
        });
    },
    initial(item, requestID) {
        return Service.post(resource + "initial", item, {
            params: { requestID: requestID },
        });
    },
    linesEnd(item, requestID) {
        return Service.post(resource + "linesEnd", item, {
            params: { requestID: requestID },
        });
    },
    linesEndProcess(item, requestID) {
        return Service.post(resource + "linesEndProcess", item, {
            params: { requestID: requestID },
        });
    },

    NumberBagsProcess(item, requestID) {
        return Service.post(resource + "returnbagsprocess", item, {
            params: { requestID: requestID },
        });
    },
    lisMtv(item, requestID) {
        return Service.post(resource + "lisMtv", item, {
            params: { requestID: requestID },
        });
    },
    lineID(item, requestID) {
        return Service.post(resource + "lineID", item, {
            params: { requestID: requestID },
        });
    },    

}