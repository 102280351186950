import Service from "../Service";
const resource = "FrzPackingLinesProcessPause/";

export default {

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

	lastPauseline(prd, requestID) {
        return Service.post(resource + "lastPauseline", prd, {
            params: { requestID: requestID },
        });
    },

};