import Service from "../Service";

const resource = "sendsealedbags/";

export default {
    save(cmp, requestID){
        return Service.post(resource + "save", cmp,{
            params: {requestID: requestID},
        });
    },
    pagination(dtr, requestID){
        return Service.post(resource + "pagination", dtr,{
            params:{requestID: requestID},
        });
    },
    list(fas, requestID) {
        return Service.post(resource + "list", fas, {
          params: { requestID: requestID },
        });
    },
    listreempaque(fas, requestID) {
        return Service.post(resource + "listreempaque", fas, {
          params: { requestID: requestID },
        });
    },
    savereempaque(cmp, requestID){
        return Service.post(resource + "savereempaque", cmp,{
            params: {requestID: requestID},
        });
    },
    // getTypePacking(cmp, requestID){
    //     return Service.post(resource + "gettypepacking", cmp,{
    //         params: {requestID: requestID},
    //     });
    // },
}